//form.scss

textarea {
    border: 1px solid $teal;
    background-color: transparent;
    font-size: 1rem;
    height: 100px;
    outline: none;
    padding: 10px;

}

.select-element {
    border: 1px solid #ccc;
    font-size: 0.8rem;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    background-color: transparent;
    outline: none;
}