//portfolio-form
.portfolio-form-wrapper {
    @include base-grid();
    grid-template-columns: 1fr;
    padding: 42px;

    @include input-element();
    input {
        margin-bottom: 0px;
    }

    .image-uploader{
        @include base-grid();
        grid-template-columns: 200px 200px 200px;

        .portfolio-manager-image-wrapper {
            img {
                width: 100%;
                height: 120px;
                object-fit: cover;
                border-radius: 5px;
            }

            .image-removal-link {
                display: flex;
                justify-content: center;

                a {
                    font-weight: 900;
                    color: $warning;
                    cursor: pointer;
                    transition: 0.5s ease-in-out;

                    &:hover {
                        text-decoration: underline;
                        color: $teal;
                    }
                }
            }
        }
    }
}

