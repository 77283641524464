.portfolio-sidebar-list-wrapper {
    .portolio-item-thumb {
       padding: 21px;

       .portolio-thumb-image img {
           width: 100%;
        }
        .text-content {
           display: flex;
           justify-content: space-between;
           align-items: center;

            .title {
                color: $offwhite;
                font-size: 1.5em;
            }

            .actions {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 15px;
                
                .action-icon {
                    cursor: pointer;
                    font-size: 1.5em;
                    color: $offwhite;
                    transition: 0.5s ease-in-out;
                    
                    &:hover {
                        color: $warning;
                    }
                }
            }
        }
    }
}