.auth-page-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 84px);

    .left-column {
        background-size: cover; 
    } 

    .right-column {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $offwhite;
        color: $teal;

        .auth-form-wrapper {
            @include base-grid();
            @include input-element();
            grid-gap: 21px;
            padding: 42px;

            .form-group {
                display: flex;
                
                svg {
                    font-size: 2em;
                    margin-right: 15px;
                }
            }

        }
    }
}



