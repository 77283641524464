//layouts

.content-page-wrapper {
    width: 100vw;
    height: calc(100vh - 84px);
    display: grid;
    grid-template-columns: 1fr 1fr;

    .right-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 100px;

        h3 {
            padding-bottom: 1px; 
            color: $charcoal;
        }

        h1 {
            color: $teal;             
        }
        .icons-list {
            display: flex;    
            padding: 20px;
            a {
                color: $grey;
                font-size: 60px;
                padding: 20px;
            }
        }

        .contact-bullet-points {
            display: grid;
            grid-gap: 21px;
            grid-template-columns: 1fr;
        }

        .bullet-point-group {
            display: flex;
            align-items: center;
        }

        .icon {
            font-size: 2em;
            color: $teal;
        }

        .text {
            font-size: 1.5em;
            margin-left: 21px;

            a {
                color: $teal;
                transition: 0.5s ease-in-out;
                text-decoration: none;

                &:hover {
                    color: $dark-teal;
                    text-decoration: underline $dark-teal;
                }
            }
        }
    }

}