//portolio-manager

.portfolio-manager-wrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;

    .left-column {
        background-color: $offwhite;
    }

    .right-column {
        background-color: $charcoal; 
    }
}