// nav bar styles 
.nav-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px;

    .right-side {
        a {
        margin-left: 15px;
        font-size: 1.5em;
        cursor: pointer;
        color: $teal;
        transition: 0.5s ease-in-out;


        &:hover {
            color: $dark-teal;
        }
        }
    }

    .left-side {
        display: flex;
    }

    .nav-link-wrapper {
        height: 22px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease-in-out;

        margin-right: 20px;
        text-transform: uppercase;
        font-size: 1.1rem;

        a {
            color: $grey;
            text-decoration: none;
        &:hover {
            color: black;
            }
        }
        &:hover {
            border-bottom: 1px solid black;
        }
        .nav-link-active {
            color: black;
            border-bottom: 1px solid black;
        }
    }
}