//blog
.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .admin-blog-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            margin-left: 15px;
            cursor: pointer;
        }
    }

    .new-blog-link {
        position: fixed;
        right: 0;
        bottom: 0;

        a {
            font-size: 3em;
            cursor: pointer;
            color: $teal;
            margin-right: 15px;
            margin-bottom: 20px;
            transition: 0.5s ease-in-out;

            &:hover {
                color: $dark-teal;
            }

        }
    }

    .content-container{
        display: flex;
        flex-direction: column;
        width: 800px;

        a {
            color: $teal;
            text-decoration: none;
            transition: 0.5s ease-in-out;

            &:hover {
                color: $dark-teal;
            } 
            h1 {
                font-size: 1.5em;
            }
        }

        .featured-image-wrapper{
            img {
                margin: 15px 0px;
                width: 100%;
                height: 350px;
                border-radius: 5px;
                object-fit: cover;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
        }
        .content {
            font-size: large;
            color: $charcoal;
            word-spacing: 0.2em;
        }
    }
}