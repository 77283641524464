//blog-form
.blog-form-wrapper {
    @include base-grid();
    grid-template-columns: 1fr;
    padding: 42px;

    @include input-element();
    input {
        margin-bottom: 0px;
    }

    .image-uploaders {
        @include base-grid;
        grid-template-columns: 200px;
    }
}